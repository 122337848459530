import React from 'react'
import { graphql } from 'gatsby'
import createLink from '../../componentUtils/createLink.js'

export const query = graphql`
fragment SkosCollectionMemberComponent on Query {
  skosCollection(id: {eq: $id}) {
    id
    member {
      id
      prefLabel {
        _language
        _value
      }
      label {
        _language
        _value
      }
    }
  }
}
`

export default function SkosCollectionMemberComponent(props) {
  const members = props.data.skosCollection.member
  return (
    <>
      {members && members.length ?
        <ul>
          {members.map(k => (<li key={`mst-${k.id}`}>{createLink(k)}</li>))}
        </ul>
        : <p>Pas de Concept associés à cette collection.</p>
      }
    </>
  )
}
