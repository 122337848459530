import React from 'react'
import { graphql } from "gatsby"
import LiteralsAsText from '../baseComponents/LiteralsAsText'

// @TODO: create a generic LiteralsComponent based on
//      - a graphql interface hierarchy : https://www.gatsbyjs.com/docs/reference/graphql-data-layer/schema-customization/#custom-interfaces-and-unions
//      - a merge of : gatsby/src/components/dashorm/cardComponents/ConceptSchemeLiteralsComponent.js
//      - and : gatsby/src/components/dashorm/cardComponents/ClassLiteralsComponent.js
// --> issue to create


export const query = graphql`
fragment SkosCollectionLiteralsComponent on Query{
  skosCollection(id: {eq: $id}) {
    id
    label {
      _language
      _value
    }
    comment {
      _language
      _value
    }
  }
}
`

export default function SkosCollectionLiteralsComponent(props) {
  const { skosCollection: entity } = props.data
  const literals = ['comment', 'example', 'status']

  return (
    <LiteralsAsText entity={entity} literalsConfig={literals}/>
  )
}
